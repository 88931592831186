import React from "react";
import SEO from "../components/seo";

const TermsOfServiceV1 = props => {
    return (
        <>
            <SEO title="Terms and Conditions V1" description={"Terms of Service are a contract between you and Scrum Mate."} />
            <section className={"privacy-policy"}>
                <div>
                    <h1>Scrum Mate Terms of Service</h1>
                    <div>
                        <h2>General</h2>
                        <p>
                            Please review these Terms of Use (the “Terms”) carefully before providing your data to Scrum Mate service owned
                            by Adaptive Consulting Ltd. (collectively “Scrum Mate”).&nbsp;By accessing, browsing or using these websites
                            (the “Services”), you (“Customer”) fully acknowledge that you have read, understood and agree to abide by these
                            Terms and comply with all applicable laws and regulations. If you do not agree to these Terms, you may not use
                            the services.
                        </p>
                    </div>
                    <div>
                        <h2>Eligibility</h2>
                        <p>
                            The Services are solely for access and use by Authorized Users (as defined below). By using the Services, you
                            represent and warrant that you (a) have the right, authority, and capacity to enter into this Agreement and (b)
                            will abide by all of the terms and conditions of this Agreement. If you have agreed to become a paying customer
                            for the Services, then your eligibility to use the Services shall be contingent on your continued compliance
                            with the terms of the applicable purchase agreement and this Agreement. If you have not agreed to become a
                            paying customer for the Services, your use of the Website is still subject to this Agreement.
                        </p>
                    </div>
                    <div>
                        <h2>Responsibility of Contributors</h2>
                        <p>
                            Without limiting any of those representations or warranties, Scrum Mate has the right (though not the
                            obligation) to, in Scrum Mate’s sole discretion (i) refuse or remove any content that, in Scrum Mate’s
                            reasonable opinion, violates any Scrum Mate policy or is in any way harmful or objectionable, or (ii) terminate
                            or deny access to and use of the service to any individual or entity for any reason, in Scrum Mate’s sole
                            discretion. Scrum Mate will have no obligation to provide a refund of any amounts previously paid.
                        </p>
                    </div>
                    <div>
                        <h2>Payment</h2>
                        <p>
                            Unless you notify Scrum Mate before the end of the applicable subscription period that you want to cancel an
                            renewal, your subscription will automatically renew and you authorize us to collect the then-applicable monthly
                            subscription fee for such renewal (as well as any taxes) using any credit card or other payment method you
                            provided. Renewals can be canceled at any time in the Upgrade Plan page accessible from your Home screen.
                        </p>
                    </div>
                    <div>
                        <h2>Use of Services</h2>
                        <p>
                            You must use the Services in a manner consistent with any and all applicable laws and regulations. Scrum Mate
                            and its suppliers use reasonable efforts to protect the confidentiality of Content you provide. Scrum Mate
                            cannot guarantee that unauthorized third parties will never be able to defeat those measures to access content
                            for improper purposes. Customer acknowledges that there are risks inherent in Internet connectivity that could
                            result in the loss of Customer’s privacy, confidential information and property. Customer also acknowledges that
                            Customer is under no obligation to provide Customer’s confidential information in order to use the Service.
                        </p>
                    </div>
                    <div>
                        <h2>Availability of and Access to Services</h2>
                        <p>
                            Scrum Mate will attempt to provide continuous availability and access to Services. In the event that we are
                            unable to provide access for reasons beyond our control, we will communicate the reasons for the outage and
                            expected duration of the outage clearly and explicitly to the Customer. These outages could be due to third
                            parties that the Service depends on, such as, but not limited to Microsoft Azure, Amazon AWS or other third
                            party service providers. Also while we will make commercially reasonable attempts to backup all Customer data,
                            in the event of recovery from disaster, the Customer may have to reconfigure the Service to get it back to the
                            state it was in before the outage. Scrum Mate is hosted by a third party hosting service provider.
                            <br />
                            Scrum Mate and its third party service providers have implemented and maintain commercially reasonable technical
                            and organizational security measures designed to meet the reliability and confidentiality of data.
                            <br />
                            However, we cannot guarantee that unauthorized third parties will never be able to defeat those measures to
                            access your data for improper purposes. You acknowledge that there are risks inherent in Internet connectivity
                            that could result in the loss of your privacy, confidential information and property.
                        </p>
                    </div>
                    <div>
                        <h2>Termination</h2>
                        <p>
                            Scrum Mate may terminate your access to all or any part of the Service at any time, with or without cause, with
                            or without notice, effective immediately. If you wish to terminate this Agreement or your Scrum Mate account (if
                            you have one), you may simply discontinue using the Service. Notwithstanding the foregoing, if you have a VIP
                            Services account, such account can only be terminated by Scrum Mate if you materially breach this Agreement and
                            fail to cure such breach within thirty (30) days from Scrum Mate’s notice to you thereof; provided that, Scrum
                            Mate can terminate the Website immediately as part of a general shut down of our service. All provisions of this
                            Agreement which by their nature should survive termination shall survive termination, including, without
                            limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
                        </p>
                    </div>
                    <div>
                        <h2>Proprietary Rights in Content of Scrum Mate</h2>
                        <p>
                            The Services contain the copyrighted material, trademarks, patents, trade secrets, and other proprietary
                            information (“Intellectual Property”) of Adaptive Consulting Ltd. and its suppliers and licensors. Adaptive
                            Consulting and its suppliers and licensors own and retain all proprietary rights in the Services.
                            <br />
                            Except for Intellectual Property which is in the public domain or for which you have been given written
                            permission, you may not copy, modify, publish, transmit, distribute, perform, display, or otherwise use any such
                            Intellectual Property and the provision of such Intellectual Property to you through the Services does not
                            transfer to you or any third party any rights, title or interest in or to such Intellectual Property, including,
                            without limitation, any intellectual property rights in any content and material included therein. You may not:
                            distribute, sub-license, translate, reverse-engineer, decompile, or disassemble the Services or Intellectual
                            Property or the source code thereof, or attempt to derive the source code thereof in any other way, save and
                            only to the extent any foregoing restriction is prohibited by applicable law; modify the Services or
                            Intellectual Property or merge all, or any part, of the Services or Intellectual Property or the source code
                            thereof into another program; or remove, modify or alter any Adaptive Consulting’s Intellectual Property from
                            any part of the Services or Intellectual Property or the source code thereof.
                            <br />
                            Adaptive Consulting grants you a perpetual, unlimited, worldwide, fully-paid, royalty-free license to copy, use
                            and make derivative works of the Output of the Services, and to make any other use of or perform any other act
                            upon the Output that an owner would be entitled to do. “Output” means all information available, reported,
                            downloaded or otherwise compiled from, the Services, including without limitation data, images, and reports
                            generated by or for the Customer.
                        </p>
                    </div>
                    <div>
                        <h2>Customer Information Provided to Scrum Mate.</h2>
                        <p>
                            Customer will retain all Intellectual Property rights in Customer Intellectual Property that may be provided by
                            Customer to Scrum Mate (“Customer Property”) under this Agreement. Scrum Mate will not publicly disclose any
                            Customer-specific information but may disclose aggregated and de-identified customer information related to the
                            Services for promotional purposes, such as project summary and high-level outcomes of the Services. You
                            understand and agree that Scrum Mate may copy, use and modify any information that you provide to Scrum Mate,
                            including all suggestions supplied by you that relate to the Services (collectively, “Content”) for the sole
                            purposes of providing the Services to you and to improve the quality of Scrum Mate products and services.
                            Furthermore, you hereby agree that Scrum Mate shall have an unlimited, royalty-free, worldwide and perpetual
                            license to use, copy, distribute, practice and make derivatives works of, under any intellectual property rights
                            you may have, including but not limited to copyrights, trademarks, trade secrets and patents, all suggestions
                            supplied by you to Scrum Mate. By providing Content to Scrum Mate, you represent and warrant that you have the
                            right to provide such Content to Scrum Mate and such Content does not infringe, misappropriate, violate or
                            contravene any laws, regulations or third party rights (including, without limitation, any rights in
                            Intellectual Property).
                        </p>
                    </div>
                    <div>
                        <h2>Disclaimer</h2>
                        <p>
                            Services are provided “As-is” and as available, with no warranty of any kind, and Scrum Mate, on behalf of
                            itself and its licensors and suppliers, expressly disclaims any warranty and conditions of any kind, whether
                            express or implied, including, but not limited to, the warranties or conditions of merchantability, fitness for
                            a particular purpose, title, accuracy, or non-infringement and Scrum Mate and its suppliers and licensors do not
                            guarantee and do not promise any specific results from the use of the Services. The Services are intended as a
                            data monitoring and collaboration tool and Customer’s use of, and reliance upon, same are Customer’s sole
                            responsibility, with Customer assuming all associated risks.
                        </p>
                    </div>
                    <div>
                        <h2>Limitation of Liability</h2>
                        <p>
                            In no event will Scrum Mate, or its suppliers or licensors, be liable with respect to any subject matter of this
                            agreement under any contract, negligence, strict liability or other legal or equitable theory for: (i) any
                            special, incidental or consequential damages; (ii) the cost of procurement for substitute products or services;
                            (iii) for interruption of use or loss or corruption of data; or (iv) for any amounts that exceed the fees paid
                            by you to Scrum Mate under this agreement during the twelve (12) month period prior to the cause of action.
                            Scrum Mate shall have no liability for any failure or delay due to matters beyond their reasonable control. The
                            foregoing shall not apply to the extent prohibited by applicable law.
                            <br />
                            General Representation and Warranty
                            <br />
                            You represent and warrant that (i) your use of the Service will be in strict accordance with the Scrum Mate
                            Privacy Policy, with this Agreement and with all applicable laws and regulations (including without limitation
                            any local laws or regulations in your country, state, city, or other governmental area, regarding online conduct
                            and acceptable content) and (ii) your use of the Service will not infringe or misappropriate the intellectual
                            property rights of any third party.
                        </p>
                    </div>
                    <div>
                        <h2>Indemnification</h2>
                        <p>
                            You agree to indemnify and hold harmless Scrum Mate, its contractors, and its licensors, and their respective
                            directors, officers, employees and agents from and against any and all claims and expenses, including attorneys’
                            fees, arising out of your use of the Website, including but not limited to your violation of this Agreement.
                        </p>
                    </div>
                    <div>
                        <h2>Publicity</h2>
                        <p>
                            You grant Scrum Mate the right to identify you as a user in Services promotional material. At any point in time
                            you can submit a written request via email to support@scrummate.com to have Scrum Mate remove your name, within
                            thirty days of your request, from promotional material.
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
};

export default TermsOfServiceV1;
